import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";

import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { TableModule } from "ngx-easy-table";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ModalComponent } from "./component/modal/modal.component";
import { ModalCieComponent } from "./component/modal-cie/modal-cie.component";

import { ReactiveFormsModule } from "@angular/forms";
 import { NgxDocViewerModule } from "ngx-doc-viewer";
import { IonicStorageModule } from "@ionic/storage";
import { NotifierModule } from "angular-notifier";
 
@NgModule({
  declarations: [AppComponent, ModalComponent, ModalCieComponent],
  entryComponents: [ModalComponent, ModalCieComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    NgxDatatableModule,
    TableModule,
    ReactiveFormsModule,
     NgxDocViewerModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: "right",
          distance: 22,
        },
        vertical: {
          position: "top",
          distance: 16,
          gap: 10,
        },
      },
      theme: "material",
      behaviour: {
        autoHide: 5000,
        onClick: false,
        onMouseover: "resetAutoHide",
        showDismissButton: true,
        stacking: 4,
      },
      animations: {
        enabled: true,
        show: {
          preset: "slide",
          speed: 300,
          easing: "ease",
        },
        hide: {
          preset: "fade",
          speed: 300,
          easing: "ease",
          offset: 50,
        },
        shift: {
          speed: 300,
          easing: "ease",
        },
        overlap: 150,
      },
    }),
    IonicStorageModule.forRoot({
      name: "_myDb",
      driverOrder: ["localstorage"],
    }),
    HttpClientModule,
    BrowserAnimationsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
