import { Injectable } from "@angular/core";

import { HttpClient, HttpHeaders } from "@angular/common/http";

import { APIURL } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApiRestService {
  constructor(public httpClient: HttpClient) {}

  obtenerCasosPectra(prestador, tipoBusqueda, entornoMedico) {
    return this.httpClient.post(APIURL + "/app/bmedica/listarCaso", {
      RutPrestador: prestador,
      isapre: entornoMedico,
      RutPaciente: "",
      Tipo: tipoBusqueda,
      NroPagina: 1,
      CantResultadosPorPagina: 100,
    });
  }

  obtenerListaCie() {
    return this.httpClient.get(APIURL + "/app/bmedica/list-cie", {});
  }
  obtenerRegiones() {
    return this.httpClient.get(APIURL + "/app/bmedica/regiones", {});
  }

  obtenerComunas() {
    return this.httpClient.get(APIURL + "/app/bmedica/comunas", {});
  }

  buscarPaciente(infoBusqueda) {
    return this.httpClient.post(
      APIURL + "/app/bmedica/paciente/find",
      infoBusqueda
    );
  }

  obtenerInformacionMedico(infoMedico) {
    return this.httpClient.post(
      APIURL + "/app/bmedica/infoPrestadores",
      infoMedico
    );
  }

  obtenerDatosProveedor(datosProveedor) {
    return this.httpClient.post(
      APIURL + "/app/bmedica/infoPrestadores",
      datosProveedor
    );
  }

  registrarCaso(infoFormulario) {
    return this.httpClient.post(
      APIURL + "/app/bmedica/registrarCaso",
      infoFormulario
    );
  }
}
