import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
import { NotifierService } from "angular-notifier";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ApiRestService } from "./api-rest.service";

@Injectable({
  providedIn: "root",
})
export class LocalstorageService {
  private readonly notifier: NotifierService;

  constructor(
    public storage: Storage,
    public apiService: ApiRestService,
    public notifierService: NotifierService
  ) {
    this.notifier = notifierService;
  }

  // set a key/value

  async set(key: string, value: any): Promise<any> {
    try {
      const result = await this.storage.set(key, value);
      return true;
    } catch (reason) {
   //   console.log(reason);

      return false;
    }
  }

  // to get a key/value pair

  async get(key: string): Promise<any> {
    try {
      const result = await this.storage.get(key);
      if (result != null) {
        return result;
      }

      return null;
    } catch (reason) {
    //  console.log(reason);
      return null;
    }
  }

  // set a key/value object

  async setObject(key: string, object: Object) {
    try {
      const result = await this.storage.set(key, JSON.stringify(object));
      return true;
    } catch (reason) {
  //    console.log(reason);
      return false;
    }
  }

  // get a key/value object

  async getObject(key: string): Promise<any> {
    try {
      const result = await this.storage.get(key);
      if (result != null) {
        return JSON.parse(result);
      }
      return null;
    } catch (reason) {
   //   console.log(reason);
      return null;
    }
  }

  // remove a single key value:

  remove(key: string) {
    this.storage.remove(key);
  }

  //  delete all data from your application:

  clear() {
    this.storage.clear();
  }

  async obtenerRegiones() {
    await this.apiService
      .obtenerRegiones()
      .pipe(catchError(this.handleError))
      .subscribe(
        (response) => {
       //   console.log(">>Cargando Regiones", response["data"].length);
          let regiones = response["data"];
          this.setObject("listaRegiones", regiones);
          this.obtenerComunas();
        },
        (error) => {
          this.notifier.notify(
            "warning",
            "No se ha podido conectar con el servidor, favor volver a intentar."
          );
          this.notifier.notify("warning", "Error : " + error["message"]);
        }
      );
  }

  async obtenerComunas() {
    await this.apiService
      .obtenerComunas()
      .pipe(catchError(this.handleError))
      .subscribe(
        (response) => {
     //     console.log(">>Cargando Comunas ", response["data"].length);

          let comunas = response["data"];
          this.setObject("listaComunas", comunas);

          this.notifier.notify("success", "Regiones & comunas sincronizadas");
        },
        (error) => {
          this.notifier.notify(
            "warning",
            "No se ha podido conectar con el servidor, favor volver a intentar."
          );
          this.notifier.notify("warning", "Error : " + error["message"]);
        }
      );
  }

  async obtenerCie() {
    await this.apiService
      .obtenerListaCie()
      .pipe(catchError(this.handleError))
      .subscribe(
        (response) => {
          let listaEnfermedades = response["data"];
          this.setObject("listaCie", listaEnfermedades);

          this.notifier.notify("success", "Lista CIE10 actualizada");
        },
        (error) => {
          this.notifier.notify(
            "warning",
            "No se ha podido conectar con el servidor, favor volver a intentar."
          );
          this.notifier.notify("warning", "Error : " + error["message"]);
        }
      );
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
