import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
        pathMatch: 'full'

    },
    {
        path: 'home/:token',
        loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
        pathMatch: 'full'

    },
    {
        path: 'home/:token',
        loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
        pathMatch: 'full'

    },
    {
        path: 'list',
        loadChildren: () => import('./list/list.module').then(m => m.ListPageModule)
    },
    {
        path: 'detalle/:id',
        loadChildren: './pages/detalle-ficha/detalle-ficha.module#DetalleFichaPageModule'
    },
    {
        path: 'ingreso-caso',
        loadChildren: './pages/ingreso-caso/ingreso-caso.module#IngresoCasoPageModule'
    },
    {
        path: 'ingreso-evaluacion-clinica/:id/:rut',
        loadChildren: './pages/ingreso-evaluacion-clinica/ingreso-evaluacion-clinica.module#IngresoEvaluacionClinicaPageModule'
    },

    {
        path: 'home/:token/ingreso-urgencia',
        loadChildren: './pages/ingreso-urgencia/ingreso-urgencia.module#IngresoUrgenciaPageModule'
    },
    {
        path: 'notificaciones/:id',
        loadChildren: './pages/notificaciones/notificaciones.module#NotificacionesPageModule'
    },
    {
        path: 'detalle/:id/detalle-evolucion/:evo',
        loadChildren: './pages/detalle-evolucion/detalle-evolucion.module#DetalleEvolucionPageModule'
    },
    {path: 'panel-control', loadChildren: './pages/panel-control/panel-control.module#PanelControlPageModule'},
    {path: 'panel-control/camas', loadChildren: './pages/configuraciones/camas/camas.module#CamasPageModule'},
    {path: 'panel-control/correo', loadChildren: './pages/configuraciones/correo/correo.module#CorreoPageModule'},
    {
        path: 'notificaciones/:id/detalle/:correo',
        loadChildren: './pages/detalle-correo/detalle-correo.module#DetalleCorreoPageModule'
    },
  { path: 'cancelar-paciente/:id/:rut', loadChildren: './pages/cancelar-paciente/cancelar-paciente.module#CancelarPacientePageModule' }



];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
