import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { APIURL } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { DomSanitizer } from "@angular/platform-browser";
import { DropzoneDirective } from "ngx-dropzone-wrapper";
const reader = new FileReader();

var mime = require("mime-types");

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
export class ModalComponent implements OnInit {
  dataBase;
  url: any;

  isLoading: any;
  mensajeLoading: any;

  constructor(
    public httpClient: HttpClient,
    public sanitizer: DomSanitizer,
    private hostElement: ElementRef
  ) {
    this.isLoading = true;
    this.mensajeLoading = "Cargando documento";
  }

  ngOnInit() {
    this.isLoading = true;
    const iframe = this.hostElement.nativeElement.querySelector("iframe");

    this.httpClient
      .post(APIURL + "/app/bmedica/buscarDocumento", {
        idDocumento: this.dataBase,
      })
      .subscribe(
        async (data)  => {
          // @ts-ignore

          console.log(data.data.DocumentoRelacionado);
          /*
          let dd = `data:${data.data.DocumentoRelacionado.MimeType};base64,${data.data.DocumentoRelacionado.Base64Data}`;
        */

          // @ts-ignore
      const byteArray = new Uint8Array(
            // @ts-ignore
            atob(data.data.DocumentoRelacionado.Base64Data)
              .split("")
              .map((char) => char.charCodeAt(0))
          );
          // @ts-ignore

        let blob = new Blob([byteArray], {
            // @ts-ignore
            type: data.data.DocumentoRelacionado.MimeType,
          });
          const url = window.URL.createObjectURL(blob);

          this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
          this.isLoading = false;

          // console.log("url", this.url);
        },
        (error) => {
          console.log(error);
        }
      );

    // console.log(`${this.IDDocumento} aaa`)
  }
}
