import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { analyzeAndValidateNgModules } from "@angular/compiler";
import { ModalController } from "@ionic/angular";
import { APIURL } from "../../../environments/environment";
import { NotifierService } from "angular-notifier";
import { LocalstorageService } from "src/app/services/localstorage.service";
import { MatPaginator } from "@angular/material";

import {
  API,
  APIDefinition,
  Columns,
  Config,
  DefaultConfig,
  Pagination,
} from "ngx-easy-table";
@Component({
  selector: "app-modal-cie",
  templateUrl: "./modal-cie.component.html",
  styleUrls: ["./modal-cie.component.scss"],
})
export class ModalCieComponent implements OnInit {
  @ViewChild("table", { static: false }) tablex: any;
  @ViewChild("table", { static: false }) table: APIDefinition;
  @ViewChild("matPage", { static: false }) paginator: MatPaginator;

  private readonly notifier: NotifierService;

  find: any;
  listaEnfermedades: any;

  listaTemporal: any;

  toggledRows = new Set<number>();
  pagination: Pagination;

  columns: Columns[] = [
    {
      key: "diagnostico",
      title: "CODIGO",
      width: "10%",
      orderEventOnly: true,
      searchEnabled: true,
    },
    {
      key: "descripcion",
      title: "NOMBRE",
      width: "70%",
      orderEnabled: true,
      searchEnabled: true,
    },
    {
      key: "acciones",
      title: "ACCIONES",
      width: "10%",
      orderEnabled: false,
      searchEnabled: false,
    },
  ];
  configuration: Config;

  data: any = [];
  rows: any = [];
  entorno: any;
  urlHome: any;
  colorTheme: any;

  public paginationTotalItems: number | void;

  constructor(
    public httpClient: HttpClient,
    private modalCtrl: ModalController,
    public notifierService: NotifierService,
    public localStorage: LocalstorageService
  ) {
    this.notifier = notifierService;
    this.listaTemporal = [];
    this.listaEnfermedades = [];

    this.configuration = { ...DefaultConfig };
    this.configuration.detailsTemplate = false;
    this.configuration.showDetailsArrow = false;
    this.configuration.searchEnabled = false;
    this.configuration.orderEnabled = true;
    this.configuration.checkboxes = false;
    this.configuration.headerEnabled = true;
    this.configuration.isLoading = true;

    this.configuration.fixedColumnWidth = false;
    this.configuration.paginationRangeEnabled = false;
    this.configuration.paginationEnabled = true;

    this.configuration.rows = 100;
  }

  ngOnInit() {
    this.obtenerListaCie();
    this.activarColorCorporativo()
  }

  activarColorCorporativo() {
    let tokenStorage = window.sessionStorage.getItem("token");
    this.urlHome = "/home/" + tokenStorage;

    let entornoCorporativo = window.sessionStorage.getItem("entorno");
    this.entorno = entornoCorporativo;

    //console.log("entornoCorporativoIngreso", entornoCorporativo);
    if (entornoCorporativo) {
      if (entornoCorporativo === "B") {
     //   console.log(">>ENTORNO", "BANMEDICA");

        this.colorTheme = "primary";
      } else {
      //  console.log(">>ENTORNO", "VIDATRES");
        this.colorTheme = "secondary";
      }
    } else {
      console.error("entorno: NO ENCONTRADO ");
    }
  }

  obtenerListaCie() {
    this.localStorage.getObject("listaCie").then(async (listaCie) => {
   //   console.log("listaCie", listaCie);

      if (listaCie) {
        setTimeout(() => {
          this.listaEnfermedades = listaCie;
          this.listaTemporal = listaCie;
          this.configuration.isLoading = false;
        }, 2000);
      } else {
        await this.localStorage.obtenerCie();

        this.obtenerListaCie();
      }
    });
  }

  filtrarLista(event) {
    let find = event.target.value.toUpperCase();

    this.listaTemporal = [];

    this.listaEnfermedades.find((item) => {
      if (item.diagnostico.includes(find) || item.descripcion.includes(find)) {
        this.listaTemporal.push(item);
      }
    });
  }

  asignarCie(item) {
    this.modalCtrl.dismiss(item);
  }
}
